<template>
  <v-main
    class="fill-height ml-4 mr-4 mt-4 mb-4"
    fluid
  >


      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <v-card class="elevation-12">
              <v-toolbar
                color="primary"
                id="LoginToolbar"
              >
                <v-toolbar-title style="color: white;">Details</v-toolbar-title>
                <div class="flex-grow-1"></div>

              </v-toolbar>
              <v-card-text>
                <v-form
                  ref="form">
                    <v-text-field
                      disabled
                      v-model="userData.email"
                      label="Email"
                    />
                    <v-text-field
                      v-model="existing_password"
                      label="Existing Password"
                    />
                      <v-text-field
                      v-model="new_password"
                      label="New Password"
                    />
                   <v-text-field
                      disabled
                      v-model="userData.plan.name"
                      label="Plan"
                    />
                      <v-text-field
                      disabled
                      v-model="userData.user_type"
                      label="User Type"
                    />
                    <v-text-field
                      disabled
                      v-if="userData.user_type == 'business'"
                      v-model="userData.vat_no"
                      label="Vat Number"
                    />
                    <v-text-field
                      disabled
                      v-model="userData.payment_method"
                      label="Payment Method"                      
                    />
                  </v-form>
              </v-card-text>
              <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="primary" @click="updatePassword()">Update Password</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>

      </v-container>
       
  <v-snackbar
        v-model="snackbar"
        :color="color"
        :top='true'
        timeout=4000

      >
        {{ snackbarMessage }}
        <v-btn
          dark

          @click="snackbar = false"
        >
          Close
        </v-btn>
      </v-snackbar>
</v-main>
</template>

<script>

export default {
   
  data: () => ({
       new_password: "",
       existing_password: "",
       userData : {plan: {}},
       snackbar: false,
       snackbarMessage: "",
       color: "general",
   
  }),
  created () {
      //get user details
       this.$store.dispatch('userData')
        .then( response => {
          this.userData = response.data
        })
        .catch(err => {
             console.log(err)
        })
  },
  methods: {

    updatePassword() {
      if(this.new_password != "" && this.existing_password != ""){
          //check existing password in node api
        this.$store.dispatch('updatePassword',{new_password: this.new_password , existing_password: this.existing_password})
            .then( response => {
                 this.snackbarMessage = response.data
                 this.snackbar = true
            })
            .catch(err => {
                console.log(err)
                this.snackbarMessage = err.response.data
                this.snackbar = true

            })
      }else{
            this.snackbarMessage = "Please fill in all the password fields"
            this.snackbar = true
      }
  },
  }
 

}
</script>
